"use client";
import Cookie from "js-cookie";

export const setCookie = (key: string, value: string, obj?: any) => {
  if (obj !== null) {
    Cookie.set(key, String(value), obj);
  } else {
    let expiresDate = new Date(
      new Date().setFullYear(new Date().getFullYear() + 2)
    );
    Cookie.set(key, String(value), {
      path: "/",
      expires: expiresDate,
      domain: process.env.NEXT_PUBLIC_DOMAIN,
    });
  }
};
