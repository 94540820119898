"use client";
import { includeLinkSearchParams } from "@/utils/wizard";
import Cookie from "js-cookie";
import { useSearchParams } from "next/navigation";
import Script from "next/script";
import { useEffect } from "react";
type Props = {};
declare const document: Document & { dataLayer: Record<string, unknown>[] };
const AsyncScripts = (props: Props) => {
  // console.log("async scripts");
  const searchParams = useSearchParams();
  const gtm_debug = searchParams.get("gtm_debug");
  useEffect(() => {
    const replaceHrefs = setTimeout(() => {
      replaceLinks();
    }, 100);
    return () => clearTimeout(replaceHrefs);
  }, []);

  return (
    <>
      <Script src="/navbar.js"></Script>
      <Script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){
            dataLayer.push(arguments);
          }

          gtag('consent', 'default', {
            'ad_personalization': "denied",
            'ad_storage':"denied",
            'ad_user_data': "denied",
            'analytics_storage':"denied",
            'functionality_storage':"denied",
            'personalization_storage': "denied",
            'security_storage':"denied"
          });`}
      </Script>
      <Script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){
            dataLayer.push(arguments);
          }

          gtag('consent', 'update', {
            'ad_personalization':  '${
              Cookie.get("enableMarketing") === "true" ? "granted" : "denied"
            }',
            'ad_storage': '${
              Cookie.get("enableMarketing") === "true" ? "granted" : "denied"
            }',
            'ad_user_data': '${
              Cookie.get("enableMarketing") === "true" ? "granted" : "denied"
            }',
            'analytics_storage': '${
              Cookie.get("enableTracking") === "true" ? "granted" : "denied"
            }',
            'functionality_storage': '${
              Cookie.get("enableFunctional") === "true" ? "granted" : "denied"
            }',
            'personalization_storage': '${
              Cookie.get("enableEssential") === "true" ? "granted" : "denied"
            }',
            'security_storage': '${
              Cookie.get("enableEssential") === "true" ? "granted" : "denied"
            }'
          });`}
      </Script>
      <Script id="google-analytics" strategy="lazyOnload">
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer', "GTM-TKT2HCR");
  `}
      </Script>
      <Script type="text/javascript">
        {`(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "jnag11t34z");`}
      </Script>
    </>
  );
};

export const replaceLinks = async () => {
  if (document) {
    let links = await document.getElementsByTagName("a");
    for (let element of links) {
      if (element.getAttribute("updated") !== "true") {
        element.href = includeLinkSearchParams(element.href);
      }
      element.setAttribute("updated", "true");
    }
  }
};

export default AsyncScripts;
